body, .modal-content, .center-flex h2 {
  font-family: 'Poppins', sans-serif;
}

.p1-style {
  min-width: 50%;
}

.p2-style {
  min-width: 70%;
  padding-top: 15vh;
  min-height: 60%;
}
.red-active.active {
  background-color: #b02318 !important;
}
.filepond--item {
  width: calc(20% - 0.5em);
}
.filepond--panel-root {
  background-color: #000 !important;
}
.filepond--root {
  min-height: 20em;
}
.filepond--credits {
  visibility: hidden;
}
.filepond--drop-label {
  color: #5098d5 !important;
}

.row-buttons {
  /* display: flex;
  flex-wrap: wrap; */
  justify-content: center;  
  margin-top: 30px !important;
  margin-right: 20% !important;
  margin-left: 20% !important;
}

/* .row-buttons .btn {
  margin: 5px;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background-color: #5098d5;
  border: 2px solid #5098d5;
  transition: all 0.3s ease;
}

.row-buttons .btn:hover {
  background-color: #fff;
  color: #5098d5;
}

.row-buttons .btn.active {
  background-color: #b02318;
  border: 2px solid #b02318;
}

.row-buttons .btn.active:hover {
  background-color: #b02318;
  border: 2px solid #b02318;
  color: #fff;
} */

.row-button-right{
  display: flex; 
  justify-content: flex-end;    
}

.row-button-left{
  display: flex; 
  justify-content: flex-start;    
}

/*
.btn-outline-custom {
  --bs-btn-border-color: #6b6868;
  --bs-btn-hover-color: #6b6868;
  --bs-btn-hover-bg: #1a1a1a;
  --bs-btn-hover-border-color: #1a1a1a;
  --bs-btn-focus-shadow-rgb: 26, 26, 26;
  --bs-btn-active-color: #fff;
  --bs-btn-active-border-color: #1a1a1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1a1a1a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1a1a1a;
  --bs-gradient: none;
}
*/
.modal-header {
  background-color:rgba(211,211,211,0.2);
  
}

.button-field-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.center-flex {
  display: flex;
  justify-content: center; 
  align-items: center;
  border:none; 
  /* height: 100vh;  */
}